<template>
  <section>
    <el-container class="flex-wrap">
      <div class="process-steps-header full-width sticky-top">
        <div class="p-20px flex-row-centered">
          <h3>{{ $t('menu.partner-admin') }}</h3>
        </div>
      </div>
      <el-main id="partnerContent">
        <el-row :gutter="24">
          <el-col :xs="24"
            :lg="5"
            class="mb-2">
            <partner-documentation />
          </el-col>
          <el-col :xs="24"
            :lg="19">
            <partner-managed-accounts />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </section>
</template>

<script>
import PartnerDocumentation from '@/components/partner/PartnerDocumentation.vue'
import PartnerManagedAccounts from '@/components/partner/PartnerManagedAccounts.vue'

export default {
  name: 'PartnerAdmin',

  components: {
    'partner-documentation': PartnerDocumentation,
    'partner-managed-accounts': PartnerManagedAccounts,
  },
}
</script>

<style lang="scss" scoped>
#partnerContent {
  padding-bottom: 50px;
  margin: auto;
}

div.process-steps-header {
  padding-top: unset;
}
</style>
