<template>
  <el-card id="partnerDocumentation"
    shadow="never"
    class="content-card">
    <template #header>
      <mu-mega-icon icon="Documentation"
        class="card-header-image" />
    </template>
    <h5>{{ $t('menu.partner-admin') }}</h5>
    <p>{{ $t('partner.learn-more') }}</p>
    <div class="flex-row-centered flex-justify-center mt-4">
      <a href="https://partner-docs.megaport.com"
        target="_blank"
        rel="noopener">
        <el-button>{{ $t('partner.view-documentation') }}</el-button>
      </a>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'PartnerDocumentation',
}
</script>

<style lang="scss" scoped>
.card-header-image {
  height: 40px;
  width: auto;
  display: block;
}
</style>
