<template>
  <el-card id="partnerManagedAccounts"
    data-testid="PartnerManagedAccounts"
    shadow="never"
    class="content-card">
    <div class="managed-accounts-heading">
      <div>
        <h3>{{ $t('partner.managed-accounts') }}</h3>
        <p>{{ $t('partner.managed-accounts-info') }}</p>
      </div>
      <el-button type="primary"
        :disabled="!hasAuth('company_admin')"
        @click="goToAccount('new')">
        {{ $t('partner.create-managed-account') }}
      </el-button>
    </div>
    <el-input v-model="textFilter"
      name="searchFilter"
      :placeholder="$t('partner.filter')"
      data-name="filter-companies"
      suffix-icon="fal fa-filter"
      class="filter-input mt-2 mb-2"
      clearable />
    <el-table v-loading="loading"
      :data="filteredAccounts"
      :default-sort="{prop: 'accountName', order: 'ascending'}"
      :empty-text="$t('partner.no-match')"
      stripe
      class="normal-wrap mt-1">
      <el-table-column prop="accountName"
        sortable
        min-width="200"
        class-name="account-name"
        :label="$t('partner.account-name')" />
      <el-table-column prop="accountRef"
        sortable
        min-width="200"
        :label="$t('partner.managed-account-ref')" />
      <el-table-column prop="techSupportPhone"
        sortable
        min-width="220"
        :label="$t('tscd.tech-supp-phone')" />
      <el-table-column prop="techSupportEmail"
        sortable
        min-width="250"
        :label="$t('tscd.tech-supp-email')" />
      <!-- Account Manger -->
      <el-table-column prop="accountManagementEmail"
        sortable
        min-width="250"
        :label="$t('company-support.account-manager')" />
      <!-- CMS -->
      <el-table-column prop="customerSuccessManagerEmail"
        sortable
        min-width="240"
        :label="$t('company-support.customer-success-manager')" />
      <el-table-column :label="$t('partner.actions')"
        header-align="center"
        min-width="230">
        <template #default="{row}">
          <div class="flex-row-centered align-element">
            <div :data-name="`${row.companyUid}-actions`"
              :data-account-name="row.accountName">
              <el-button @click="viewServices(row)">
                {{ $t('partner.view-services') }}
              </el-button>
              <el-button type="primary"
                :disabled="!hasAuth('company_admin')"
                @click="goToAccount(row.companyUid)">
                {{ $t('partner.edit') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

const ACCOUNT_ROUTE = '/partner/managed'

export default {
  name: 'PartnerManagedAccounts',

  data() {
    return {
      textFilter: '',
      loading: true,
    }
  },

  computed: {
    ...mapState('ManagedCompanies', ['managedCompaniesList']),
    ...mapState('Company', ['data']),
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('Company', ['companyUid']),
    ...mapActions('ManagedCompanies', ['getManagedCompanies']),
    filteredAccounts() {
      return this.managedCompaniesList
        .filter(account => {
          const tempStr = `${account.accountName} ${account.accountRef}`.toLowerCase()
          return tempStr.includes(this.textFilter.toLowerCase())
        })
    },
  },

  async created() {
    try {
      await this.getManagedCompanies
    } catch {
      const props = {
        title: this.$t('partner.error'),
        message: this.$t('partner.error-retrieving-accounts'),
        duration: 0,
      }
      this.$notify.error(props)
    } finally {
      this.loading = false
    }
  },

  methods: {
    goToAccount(companyUid) {
      this.$router.push(`${ACCOUNT_ROUTE}/${companyUid}`, () => { })
    },
    isCompanyManaged(companyUid) {
      return !!this.managedCompaniesList.find(company => company.companyUid === companyUid)
    },
    getAccountType(isManaged) {
      return isManaged ? this.$tc('partner-admin.managed-account', 1) : this.$t('partner-admin.partner-account')
    },
    viewServices({ companyUid, accountName }) {
      if (this.companyUid === companyUid) {
        this.$router.push(resolveServicesPage())
      } else {
        const props = {
          accountTypeFrom: this.getAccountType(this.isCompanyManaged(this.companyUid)),
          fromCompany: this.data.legalName,
          accountTypeTo: this.getAccountType(this.isCompanyManaged(companyUid)),
          toCompany: accountName,
        }

        this.$confirm(this.$t('general.context-switching-message', props), this.$t('general.context-switching-title'), {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('general.confirm'),
          cancelButtonText: this.$t('general.cancel'),
          showClose: false,
          closeOnClickModal: false,
        }).then(() => {
          this.$router.push({
            path: resolveServicesPage(),
            query: {
              managedCompanyUid: companyUid,
              companyName: accountName,
            },
          }).catch(() => { })
        }).catch(() => { })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.managed-accounts-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.filter-input {
  max-width: 400px;
}
::v-deep .account-name .cell {
  font-weight: 700;
}
.align-element {
  justify-content: space-around;
}
</style>
